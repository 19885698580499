.box1 {
  height: 50px;
  width: 50px;
  border: solid black 1px;
  display: inline-flex;
  margin: 3px;
  align-items: center;
  justify-content: center;
  background-color: #ce7474;
}

.box2{
  @extend .box1;
  background-color: #38cc93;
}
.box3{
 @extend .box1;
 background-color: #4571c2;
}


